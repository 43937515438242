import React, { useCallback, useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { HttpV2 } from '@shared/apiv2/api'
import { InputText } from '@/components/InputText'

export const ApiKeysPanel = () => {
    const [apiKeyState, setApiKeyState] = useState({ apiKeys: [], apiKeysLoading: false })
    const { apiKeys, apiKeysLoading } = apiKeyState
    const notify = useNotify()
    const [sfAccountId, setSfAccountId] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const reloadKeys = useCallback(async () => {
        try {
            setApiKeyState((prev: any) => ({ ...prev, apiKeysLoading: true }))
            const response = await HttpV2.get('/admin/api-keys')
            console.log(response)
            setApiKeyState((prev: any) => ({ ...prev, apiKeys: response.data, apiKeysLoading: false }))
        } catch (error: any) {
            notify(`Error in loading api keys: ${error.message}`, { variant: 'error' })
        }
    }, [])

    useEffect(() => {
        reloadKeys()
    }, [])

    const setupApiKeyForAccount = async (accId: string) => {
        return HttpV2.post('/admin/api-keys/' + accId)
    }

    const handleKeySetup = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await setupApiKeyForAccount(sfAccountId)
            // copy data
            if (navigator?.clipboard) {
                navigator.clipboard.writeText(data)
            }
            notify('API Key setup completed successfully (key copied to clipboard)', { variant: 'success' })
            setSfAccountId('')
            reloadKeys()
        } catch (e: any) {
            notify(`Error in setting up api key: ${e.message}`, { variant: 'error' })
        }
        setLoading(false)
    }, [sfAccountId])

    return (
        <Card style={{ marginTop: 32 }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    API Keys
                </Typography>
                {/*    input + button to add new api key */}
                <Grid container spacing={2}>
                    <Grid item>
                        <InputText
                            text
                            type="text"
                            name={'sfAccountId'}
                            label={'SF Account ID'}
                            value={sfAccountId}
                            onChange={(e: any) => {
                                setSfAccountId(e.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item marginTop={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            size={'small'}
                            disabled={loading}
                            onClick={handleKeySetup}
                        >
                            {loading ? <CircularProgress size={20} /> : 'Setup New API Key'}
                        </Button>
                    </Grid>
                </Grid>

                {/*    create table with list of api keys */}
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            API Keys
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {apiKeys.map((key: any) => (
                        <Grid item key={key.id}>
                            <Typography variant="body1">
                                <b>Account</b> {key.accountId}
                            </Typography>
                            <Typography variant="caption">
                                <p>{key.apiKey}</p>
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    )
}
