import React, { FC } from 'react'
import { useUserState } from '@/admin/store/user'
import { Navigate } from 'react-router-dom'
import { useAdminUser } from '@/admin/api/admin.api'
import { UserActionType } from '@/admin/store/user/user'
import dataProvider from '@/admin/api/data-provider'
import { Admin, Resource, Show, Create } from 'react-admin'
import { OrdersList } from '@/admin/resources/order/order'
import { CompanyList } from '@/admin/resources/company/company'
import { CompanyView } from '@/admin/resources/company/company-view'
import { ManagerView } from '@/admin/resources/manager/manager-view'
import { InvoiceView } from './resources/invoice/invoice'
import { OrderView, OV_CLASS_NAME } from './resources/order/order-view'
import Policy from '@mui/icons-material/Policy'
import Apartment from '@mui/icons-material/Apartment'
import Person from '@mui/icons-material/Person'
import LocalShipping from '@mui/icons-material/LocalShipping'
import Warehouse from '@mui/icons-material/Warehouse'
import Percent from '@mui/icons-material/Percent'
import TocIcon from '@mui/icons-material/Toc'
import SettingsIcon from '@mui/icons-material/Settings'
import ArticleIcon from '@mui/icons-material/Article'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import BoltIcon from '@mui/icons-material/Bolt'
import CategoryIcon from '@mui/icons-material/Category'
import ModeCommentIcon from '@mui/icons-material/ModeComment'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DevOpsIcon from '@mui/icons-material/DeveloperBoard'
import ApiKeyIcon from '@mui/icons-material/Key'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import SmsIcon from '@mui/icons-material/Sms'
import TuneIcon from '@mui/icons-material/Tune'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'

import { AppLayout } from './AppLayout'
import { WarehouseExports } from '@/admin/resources/warehouse/warehouse-exports'
import { ManagerList } from '@/admin/resources/manager/manager'
import { CarrierList } from './resources/carrier/carrier'
import { WarehouseList } from '@/admin/resources/warehouse/warehouse'
import { PriceSheetList } from './resources/price-sheet/price-sheet'
import { CreateSheet } from '@/admin/resources/price-sheet/create-sheet'
import BreadcrumbsLayout from '@/admin/parts/Layout/BreadcrumbsLayout'
import GeneralModal from '@/admin/parts/GeneralModal'
import { TOSList } from '@/admin/resources/tos/tos'
import { TOS_VIEW_CLASSNAME, TOSView } from '@/admin/resources/tos/tos-view'
import { TOSCreate } from '@/admin/resources/tos/tos-create'
import { CarrierView } from './resources/carrier/carrier-view'
import { WarehouseView } from './resources/warehouse/warehouse-view'
import { WarehouseServiceView } from './resources/warehouse/warehouse-service-view'
import { WarehouseDocumentView } from './resources/warehouse/warehouse-document-view'
import { ServiceUnityList } from './resources/service-unity/service-unity'
import { SettingsCarrier } from './resources/carrier/carrier-setting-type'
import { WarehouseServiceList } from './resources/warehouse/warehouse-service-list'
import { WarehouseDocumentsList } from './resources/warehouse/warehouse-documents-list'
import { DayPriceMarkupList } from './resources/price-markup/day-price-markup/list'
import { RangePriceMarkupList } from './resources/price-markup/range-price-markup/list'
import { TimePriceMarkupList } from './resources/price-markup/time-price-markup/list'
import { CalendarPriceMarkupList } from './resources/price-markup/calendar-price-markup/list'
import { CategorySupportList } from './resources/support/category/list'
import { MessagesSupportList } from './resources/support/message/list'
import { FeatureFlagsList } from './resources/feature-flags/feature-flags'
import { EditFeatureFlags } from './resources/feature-flags/feature-flags-edit'
import { CreateFeatureFlags } from './resources/feature-flags/feature-flags-create'
import { EventLogList } from './resources/system/eventLog/list'
import { SmsLogList } from './resources/system/smsLog/list'
import { PushNotificationLog } from './resources/system/pushNotificationLog/list'
import { ShowPushNotificationLog } from './resources/system/pushNotificationLog/show'
import { SystemEnvironmentList } from './resources/system/systemEnv/list'
import { AdminList } from './resources/system/admin/list'
import { ReferralList } from './resources/system/referral/list'

import './AppContainer.scss'
import { DevopsPanel } from '@/admin/resources/devops'
import { ApiKeysPanel } from '@/admin/resources/api-keys'

export const AppContainer: FC = () => {
    const { state, dispatch } = useUserState()
    const user = useAdminUser(state.isLoggedIn)

    if (!state.isLoggedIn) {
        return <Navigate to="/admin/login" />
    }

    if (user.error) {
        dispatch!({ type: UserActionType.LOGOUT })
        return <Navigate to="/admin/login" />
    }

    return (
        <Admin basename="/admin/app" dataProvider={dataProvider} layout={AppLayout}>
            <Resource
                name="orders"
                list={OrdersList}
                icon={TocIcon}
                options={{
                    label: 'Orders',
                }}
                show={
                    <Show className={OV_CLASS_NAME}>
                        <GeneralModal />
                        <OrderView />
                    </Show>
                }
            />
            <Resource
                name="companies"
                icon={Apartment}
                options={{
                    label: 'Companies',
                }}
                list={
                    <BreadcrumbsLayout name="companies">
                        <CompanyList />
                    </BreadcrumbsLayout>
                }
                show={
                    <Show className={OV_CLASS_NAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="companies_show">
                            <CompanyView />
                        </BreadcrumbsLayout>
                    </Show>
                }
            />
            <Resource
                name="managers"
                icon={Person}
                options={{ label: 'Managers' }}
                list={
                    <BreadcrumbsLayout name="managers">
                        <ManagerList />
                    </BreadcrumbsLayout>
                }
                show={
                    <Show className={OV_CLASS_NAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="managers_show">
                            <ManagerView />
                        </BreadcrumbsLayout>
                    </Show>
                }
            />
            <Resource
                name="carriers"
                icon={LocalShipping}
                options={{
                    label: 'Carriers',
                    children: ['carriers', 'setting-type'],
                }}
                list={
                    <BreadcrumbsLayout name="carriers">
                        <GeneralModal />
                        <CarrierList />
                    </BreadcrumbsLayout>
                }
                show={
                    <Show className={OV_CLASS_NAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="carriers_show">
                            <CarrierView />
                        </BreadcrumbsLayout>
                    </Show>
                }
            />
            <Resource
                name="setting-type"
                icon={SettingsIcon}
                options={{
                    label: 'Setting type',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="setting-type">
                        <GeneralModal />
                        <SettingsCarrier />
                    </BreadcrumbsLayout>
                }
            />
            <Resource
                name="warehouses"
                icon={Warehouse}
                options={{
                    label: 'Warehouses',
                    children: ['warehouses', 'warehouse-documents', 'warehouse-services', 'warehouse-exports'],
                }}
                list={
                    <BreadcrumbsLayout name="warehouse">
                        <GeneralModal />
                        <WarehouseList />
                    </BreadcrumbsLayout>
                }
                show={
                    <Show className={OV_CLASS_NAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="warehouse_show">
                            <WarehouseView />
                        </BreadcrumbsLayout>
                    </Show>
                }
            />
            <Resource name="invoices" edit={InvoiceView} />
            <Resource
                name="price-sheets"
                icon={Percent}
                options={{
                    label: 'Pricing',
                    children: [
                        'price-sheets',
                        'day-price-markup',
                        'range-price-markup',
                        'time-price-markup',
                        'calendar-price-markup',
                    ],
                }}
                list={PriceSheetList}
                create={CreateSheet}
            />
            <Resource
                name="day-price-markup"
                icon={InsertInvitationIcon}
                options={{
                    label: 'Day Price Markup',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="day-price-markup">
                        <GeneralModal />
                        <DayPriceMarkupList />
                    </BreadcrumbsLayout>
                }
            />
            <Resource
                name="range-price-markup"
                icon={DateRangeIcon}
                options={{
                    label: 'Range Price Markup',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="range-price-markup">
                        <GeneralModal />
                        <RangePriceMarkupList />
                    </BreadcrumbsLayout>
                }
            />
            <Resource
                name="time-price-markup"
                icon={AccessTimeIcon}
                options={{
                    label: 'Time Price Markup',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="time-price-markup">
                        <GeneralModal />
                        <TimePriceMarkupList />
                    </BreadcrumbsLayout>
                }
            />
            <Resource
                name="calendar-price-markup"
                icon={CalendarMonthIcon}
                options={{
                    label: 'Calendar Price Markup',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="calendar-price-markup">
                        <GeneralModal />
                        <CalendarPriceMarkupList />
                    </BreadcrumbsLayout>
                }
            />

            <Resource
                name="support"
                icon={BoltIcon}
                options={{
                    label: 'Support',
                    children: ['messages-support', 'category-support'],
                }}
                list={
                    <BreadcrumbsLayout name="support_list">
                        <GeneralModal />
                        <MessagesSupportList />
                    </BreadcrumbsLayout>
                }
            />

            <Resource
                name="messages-support"
                icon={ModeCommentIcon}
                options={{
                    label: 'Messages',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="support_list">
                        <GeneralModal />
                        <MessagesSupportList />
                    </BreadcrumbsLayout>
                }
            />

            <Resource
                name="category-support"
                icon={CategoryIcon}
                options={{
                    label: 'Categories',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="support_category_list">
                        <GeneralModal />
                        <CategorySupportList />
                    </BreadcrumbsLayout>
                }
            />

            <Resource
                name="terms-of-service"
                icon={Policy}
                options={{ label: 'Terms of Service' }}
                list={TOSList}
                create={
                    <Create className={TOS_VIEW_CLASSNAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="terms_of_service">
                            <TOSCreate />
                        </BreadcrumbsLayout>
                    </Create>
                }
                show={
                    <Show className={TOS_VIEW_CLASSNAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="terms_of_service">
                            <TOSView />
                        </BreadcrumbsLayout>
                    </Show>
                }
            />

            <Resource
                name="warehouse-services"
                icon={DesignServicesIcon}
                list={
                    <BreadcrumbsLayout name="warehouse_service">
                        <GeneralModal />
                        <WarehouseServiceList />
                    </BreadcrumbsLayout>
                }
                show={
                    <Show className={OV_CLASS_NAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="warehouse_service_show">
                            <WarehouseServiceView />
                        </BreadcrumbsLayout>
                    </Show>
                }
                options={{
                    label: 'Warehouse Service',
                    submenu: true,
                }}
            />
            <Resource
                icon={ArticleIcon}
                name="warehouse-documents"
                options={{
                    label: 'Warehouse Documents',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="warehouse_document">
                        <GeneralModal />
                        <WarehouseDocumentsList />
                    </BreadcrumbsLayout>
                }
                show={
                    <Show className={OV_CLASS_NAME}>
                        <GeneralModal />
                        <BreadcrumbsLayout name="warehouse_document_show">
                            <WarehouseDocumentView />
                        </BreadcrumbsLayout>
                    </Show>
                }
            />
            <Resource
                icon={ArticleIcon}
                name="warehouse-exports"
                options={{
                    label: 'Warehouse Exports',
                    submenu: true,
                }}
                list={
                    <BreadcrumbsLayout name="warehouse_exports">
                        <GeneralModal />
                        <WarehouseExports />
                    </BreadcrumbsLayout>
                }
            />
            <Resource
                name="service-unity"
                options={{
                    label: 'Service Unity',
                    submenu: true,
                }}
                list={
                    <>
                        <GeneralModal />
                        <ServiceUnityList />
                    </>
                }
            />
            <Resource
                name="system"
                icon={TuneIcon}
                options={{
                    label: 'System',
                    children: [
                        'referral-system',
                        'event-log-system',
                        'sms-log-system',
                        'admin-system',
                        'push-notification-system',
                        'system-env',
                        'devops',
                        'api-keys',
                    ],
                }}
                list={
                    <>
                        <GeneralModal />
                        <WarehouseDocumentsList />
                    </>
                }
            />
            <Resource
                name="referral-system"
                icon={GroupAddIcon}
                options={{
                    label: 'Referral',
                    submenu: true,
                }}
                list={
                    <>
                        <GeneralModal />
                        <ReferralList />
                    </>
                }
            />
            <Resource
                name="devops"
                icon={DevOpsIcon}
                options={{
                    label: 'Dev Ops',
                    submenu: true,
                }}
                list={
                    <>
                        <DevopsPanel />
                        <GeneralModal />
                    </>
                }
            />
            <Resource
                name="api-keys"
                icon={ApiKeyIcon}
                options={{
                    label: 'API Keys',
                    submenu: true,
                }}
                list={
                    <>
                        <ApiKeysPanel />
                        <GeneralModal />
                    </>
                }
            />
            <Resource
                name="event-log-system"
                icon={ReportProblemIcon}
                options={{
                    label: 'Event log',
                    submenu: true,
                }}
                list={
                    <>
                        <EventLogList />
                    </>
                }
            />
            <Resource
                name="sms-log-system"
                icon={SmsIcon}
                options={{
                    label: 'SMS log',
                    submenu: true,
                }}
                list={
                    <>
                        <SmsLogList />
                    </>
                }
            />
            <Resource
                name="push-notification-system"
                icon={NotificationsActiveIcon}
                options={{
                    label: 'Push notifications log',
                    submenu: true,
                }}
                list={<PushNotificationLog />}
                show={
                    <Show className={OV_CLASS_NAME}>
                        <ShowPushNotificationLog />
                    </Show>
                }
            />
            <Resource
                name="system-env"
                icon={SettingsSuggestIcon}
                options={{
                    label: 'System Environment',
                    submenu: true,
                }}
                list={
                    <>
                        <GeneralModal />
                        <SystemEnvironmentList />
                    </>
                }
            />
            <Resource
                name="admin-system"
                icon={SupervisorAccountIcon}
                options={{
                    label: 'Administrators',
                    submenu: true,
                }}
                list={
                    <>
                        <GeneralModal />
                        <AdminList />
                    </>
                }
            />
            <Resource
                recordRepresentation="key"
                // edit={EditFeatureFlags}
                // create={CreateFeatureFlags}
                name="feature-flags"
                icon={ToggleOnIcon}
                options={{
                    label: 'Feature Flags',
                }}
                list={
                    <BreadcrumbsLayout name="feature-flags">
                        <FeatureFlagsList />
                    </BreadcrumbsLayout>
                }
            />
        </Admin>
    )
}
